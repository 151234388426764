import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Layout from "../components/layout";
import PieceListItem from "../components/pieceListItem";
import SEO from "../components/seo";
import SiteBackground from "../components/siteBackground";


const Ceramics = () => {
  const data = useStaticQuery(graphql`
    query {
      info: allCeramicJson(sort: {fields: sortDate, order: DESC}) {
        nodes {
            date
            title
            size
            material
            desc
            short_desc
            directory
            sortDate
            image: mainImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
  `)
  const pieceType = "ceramic"

  return (
    <SiteBackground>
      <Layout>
        <SEO title="Elnaz Nourizadeh Ceramics" />
        <h1>Ceramics</h1>
        <Card className="mb-3">
          <Card.Body>
            <Card.Text>This is a selection of my ceramics, you can find more <a href="https://elnaz.melbourne">here</a></Card.Text>
          </Card.Body>
        </Card>
        <Row>
          {data.info.nodes.map(i =>
            <Col md={6} lg={4} className="d-flex">
              <PieceListItem item={i} pieceType={pieceType} />
            </Col>
          )}
        </Row>
      </Layout>
    </SiteBackground>
  )
}

export default Ceramics
